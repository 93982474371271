@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
ul,
li,
span {
  line-height: 1.6;
  font-family: 'Roboto', 'Noto Sans TC', sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
}
.uppercase {
  text-transform: uppercase;
}
.uppercase.soli::first-letter {
    font-size: 110%;
    font-weight: 400;
}
.card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10pt;
}
@media screen and (min-width: 768px) {
.card-container {
      width: 768px;
      display: grid;
      grid-template-areas: "head head" "about contact" "experience contact" "experience skill";
      grid-template-columns: 1fr 18rem;
}
.card-container #head {
        grid-area: head;
}
.card-container #contact {
        grid-area: contact;
}
.card-container #about {
        grid-area: about;
}
.card-container #skill {
        grid-area: skill;
}
.card-container #experience {
        grid-area: experience;
}
.card-container #project {
        grid-area: project;
}
}
.card-container .card {
    border-radius: 16pt;
    background-color: #202124;
    -webkit-box-shadow: 2px 2px 6px #2c2d32, -2px -2px 6px #141517;
            box-shadow: 2px 2px 6px #2c2d32, -2px -2px 6px #141517;
    padding: inherit;
    margin: 10pt;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    color: #FFFFFF;
    text-shadow: 1px 1px 2px #080809, -1px -1px 2px #383a3f;
}
.card-container .card h2 {
      font-weight: 500;
}
.card-container .card p {
      margin-top: 5pt;
      font-size: 11pt;
      color: #A6A8AA;
}
.card-container .card p.tag {
        font-weight: 500;
        font-size: 9pt;
        display: inline-block;
        color: #202124;
        border-radius: 1rem;
        padding: 0 5pt;
        margin-top: 10pt;
        background: linear-gradient(135deg, #fdac1a, #b07201);
        -webkit-box-shadow: inset 2px 2px 4px #644101, inset -2px -2px 4px #c98202;
                box-shadow: inset 2px 2px 4px #644101, inset -2px -2px 4px #c98202;
        text-shadow: 1px 1px 2px #c98202, -1px -1px 2px #fdac1a;
}
.card-container .card.card-head {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-flow: wrap;
          flex-flow: wrap;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      position: relative;
      margin-top: 30pt;
}
.card-container .card.card-head .image {
        background-position: center;
        background-size: cover;
        height: 80pt;
        width: 80pt;
        border-radius: 50%;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-shadow: 2px 2px 6px #2c2d32, -2px -2px 6px #141517;
                box-shadow: 2px 2px 6px #2c2d32, -2px -2px 6px #141517;
        position: absolute;
        left: 10pt;
        top: -30pt;
}
.card-container .card.card-head .content {
        width: calc(100% - 90pt);
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-left: auto;
        text-shadow: 1px 1px 2px #080809, -1px -1px 2px #383a3f;
}
.card-container .card.card-head .content h1 {
          font-weight: 500;
          line-height: 1 !important;
}
@media screen and (max-width: 320px) {
.card-container .card.card-head .content h1 {
              font-size: 22pt;
}
}
.card-container .card.card-head .content p {
          margin: 0;
          text-shadow: 1px 1px 2px #080809, -1px -1px 2px #383a3f;
}
.contact-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 5pt;
  padding: 0 5pt;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media screen and (min-width: 768px) {
.contact-list {
      padding: 0 10pt;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
}
}
.contact-list .contact {
    text-decoration: none;
    color: #A6A8AA;
    cursor: pointer;
}
.contact-list .contact p {
      display: none;
      margin-left: 1rem;
      text-shadow: 1px 1px 2px #080809, -1px -1px 2px #383a3f;
}
.contact-list .contact .icon {
      background: linear-gradient(135deg, #fdac1a, #b07201);
      -webkit-box-shadow: 2px 2px 3px #322000, -1px -1px 3px #976201;
              box-shadow: 2px 2px 3px #322000, -1px -1px 3px #976201;
      -webkit-transition: -webkit-box-shadow ease-in-out .3s;
      transition: -webkit-box-shadow ease-in-out .3s;
      transition: box-shadow ease-in-out .3s;
      transition: box-shadow ease-in-out .3s, -webkit-box-shadow ease-in-out .3s;
      border-radius: 50%;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 14pt;
      height: 2.3rem;
      width: 2.3rem;
      text-align: center;
      color: #202124;
}
.contact-list .contact:hover .icon {
      -webkit-box-shadow: 2px 2px 3px #7e5101, -1px -1px 3px #c98202;
              box-shadow: 2px 2px 3px #7e5101, -1px -1px 3px #c98202;
}
.contact-list .contact:hover span {
      font-weight: 500;
      color: #FCA302;
}
@media screen and (min-width: 768px) {
.contact-list .contact {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        margin: 5pt 0;
}
.contact-list .contact p {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
}
}
.skill-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 2pt 0;
}
.skill-container p {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0 !important;
    margin-right: auto !important;
}
.skill-item {
  margin: 5pt 0;
}
.skill-item p {
    line-height: 1.4;
    font-size: 12px !important;
    margin: 0 !important;
}
#footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20pt;
  text-shadow: 1px 1px 2px #080809, -1px -1px 2px #383a3f;
}
#footer p {
    font-size: 10pt;
    color: #66686b;
    opacity: .5;
}
#footer p:first {
      border-right: 1px solid #A6A8AA;
}
#footer a {
    color: inherit;
    font-weight: 500;
}
html, body {
  background-color: #202124;
}
#app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 100vh;
  background-color: #202124;
}
